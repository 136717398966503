/* ----
   Stylesheet For Custom Elements On All Pages
   ========================================================================== */
/**
 * Foundation for Sites by ZURB
 * Version 6.2.1
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
/* ----
   Page Settings
   ========================================================================== */
/* ==> Common Variables */
/* ==> Mixins & Extendables */
/* ==> Foundation Overrides */
body {
  overflow-x: hidden;
}

.lang-gr h1, .lang-gr h2, .lang-gr h3, .lang-gr h4, .lang-gr h5, .lang-gr h6 {
  font-family: "Source Sans Pro", Arial, sans-serif;
}

a {
  outline: 0 none;
}

figure {
  margin: 0;
}

address {
  font-style: normal;
}

button.wide,
.button.wide {
  padding-left: 2em;
  padding-right: 2em;
}

textarea {
  min-height: 147px;
}

[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'],
textarea,
select {
  margin-bottom: 0;
}

option:disabled {
  color: #a69e95;
  font-style: italic;
}

::-webkit-input-placeholder {
  font-style: italic;
}

::-moz-placeholder {
  font-style: italic;
  opacity: 1;
}

:-ms-input-placeholder {
  font-style: italic;
}

:-moz-placeholder {
  font-style: italic;
  opacity: 1;
}

.reveal-inner {
  padding: 10px;
}

/* ----
   Alpha Color Scheme
   ========================================================================== */
@media screen and (min-width: 64em) {
  .page-header .secondary {
    margin-top: 20px;
  }
}

.pronia-alpha .page-header .phone {
  color: #6ca642;
}

.pronia-alpha .page-header .phone i {
  color: inherit;
}

.pronia-alpha .slide-body {
  color: #fff;
}

.pronia-alpha .slide-body h4 {
  line-height: 1.2em;
}

.pronia-alpha .slide-caption {
  background: #6ca642;
}

.pronia-alpha .page-content-primary h2:after,
.pronia-alpha .branded-title:after,
.pronia-alpha .branded-title-plain:after {
  background: url("images/bg-branded-title.png") no-repeat left top transparent;
  height: 5px;
  width: 252px;
}

.pronia-alpha .page-content-primary ul li {
  list-style-image: url("images/dot-primary.png");
}

.pronia-alpha .page-content-primary ul.no-bullet li {
  list-style-image: none;
}

.pronia-alpha .button-brand {
  background: url("images/button-branded-middle.png") repeat-x left top transparent;
  border: 0 none;
  color: #fbb524;
  display: inline-block;
  font-size: 1em;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 48px;
  margin-left: 26px;
  margin-right: 22px;
  padding: 2px 15px 0;
  position: relative;
  text-transform: uppercase;
}

.pronia-alpha .button-brand:hover, .pronia-alpha .button-brand:active {
  color: #fff;
}

.pronia-alpha .button-brand:before, .pronia-alpha .button-brand:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  height: 50px;
}

.pronia-alpha .button-brand:before {
  background: url("images/button-branded-left.png") no-repeat left top;
  left: -26px;
  width: 26px;
}

.pronia-alpha .button-brand:after {
  background: url("images/button-branded-right.png") no-repeat left top;
  right: -22px;
  width: 22px;
}

.pronia-alpha .button-brand-small {
  background-image: url("images/button-branded-small-middle.png");
  line-height: 30px;
  padding: 3px 6px 5px;
}

.pronia-alpha .button-brand-small:before, .pronia-alpha .button-brand-small:after {
  height: 38px;
}

.pronia-alpha .button-brand-small:before {
  background-image: url("images/button-branded-small-left.png");
  left: -20px;
  width: 20px;
}

.pronia-alpha .button-brand-small:after {
  background-image: url("images/button-branded-small-right.png");
  right: -17px;
  width: 17px;
}

.pronia-alpha .button-brand--expanded {
  display: block;
  width: calc(100% - 48px);
}

.pronia-alpha .page-footer .pf-primary {
  background: #6ca642;
  border-bottom: 0;
}

.pronia-alpha .page-footer .social-list {
  margin: 0 auto;
}

.pronia-alpha .page-footer .social-list-transparent a .fa {
  color: #6ca642;
}

.pronia-alpha .event-item-date .date {
  background: #6ca642;
  font-family: "Source Sans Pro", Arial, sans-serif;
  font-weight: 700;
  color: #333;
}

@media screen and (min-width: 41.25em) {
  .pronia-alpha .slide-body h4 {
    font-size: 2.3125em;
  }
  .pronia-alpha .slide-caption {
    background: transparent;
  }
}

/* ----
   Base rules
   ========================================================================== */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url("fonts/roboto/roboto-light.eot");
  src: local("Roboto Light"), local("Roboto-Light"), url("fonts/roboto/roboto-light.eot?#iefix") format("embedded-opentype"), url("fonts/roboto/roboto-light.woff2") format("woff2"), url("fonts/roboto/roboto-light.woff") format("woff"), url("fonts/roboto/roboto-light.ttf") format("truetype"), url("fonts/roboto/roboto-light.svg#Roboto") format("svg");
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  src: url("fonts/roboto/roboto-lightitalic.eot");
  src: local("Roboto Light Italic"), local("Roboto-LightItalic"), url("fonts/roboto/roboto-lightitalic.eot?#iefix") format("embedded-opentype"), url("fonts/roboto/roboto-lightitalic.woff2") format("woff2"), url("fonts/roboto/roboto-lightitalic.woff") format("woff"), url("fonts/roboto/roboto-lightitalic.ttf") format("truetype"), url("fonts/roboto/roboto-lightitalic.svg#Roboto") format("svg");
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url("fonts/roboto/roboto-regular.eot");
  src: local("Roboto"), local("Roboto-Regular"), url("fonts/roboto/roboto-regular.eot?#iefix") format("embedded-opentype"), url("fonts/roboto/roboto-regular.woff2") format("woff2"), url("fonts/roboto/roboto-regular.woff") format("woff"), url("fonts/roboto/roboto-regular.ttf") format("truetype"), url("fonts/roboto/roboto-regular.svg#Roboto") format("svg");
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  src: url("fonts/roboto/roboto-regularitalic.eot");
  src: local("Roboto Italic"), local("Roboto-Italic"), url("fonts/roboto/roboto-regularitalic.eot?#iefix") format("embedded-opentype"), url("fonts/roboto/roboto-regularitalic.woff2") format("woff2"), url("fonts/roboto/roboto-regularitalic.woff") format("woff"), url("fonts/roboto/roboto-regularitalic.ttf") format("truetype"), url("fonts/roboto/roboto-regularitalic.svg#Roboto") format("svg");
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url("fonts/roboto/roboto-medium.eot");
  src: local("Roboto Medium"), local("Roboto-Medium"), url("fonts/roboto/roboto-medium.eot?#iefix") format("embedded-opentype"), url("fonts/roboto/roboto-medium.woff2") format("woff2"), url("fonts/roboto/roboto-medium.woff") format("woff"), url("fonts/roboto/roboto-medium.ttf") format("truetype"), url("fonts/roboto/roboto-medium.svg#Roboto") format("svg");
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  src: url("fonts/roboto/roboto-mediumitalic.eot");
  src: local("Roboto Medium Italic"), local("Roboto-MediumItalic"), url("fonts/roboto/roboto-mediumitalic.eot?#iefix") format("embedded-opentype"), url("fonts/roboto/roboto-mediumitalic.woff2") format("woff2"), url("fonts/roboto/roboto-mediumitalic.woff") format("woff"), url("fonts/roboto/roboto-mediumitalic.ttf") format("truetype"), url("fonts/roboto/roboto-mediumitalic.svg#Roboto") format("svg");
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url("fonts/roboto/roboto-bold.eot");
  src: local("Roboto Bold"), local("Roboto-Bold"), url("fonts/roboto/roboto-bold.eot?#iefix") format("embedded-opentype"), url("fonts/roboto/roboto-bold.woff2") format("woff2"), url("fonts/roboto/roboto-bold.woff") format("woff"), url("fonts/roboto/roboto-bold.ttf") format("truetype"), url("fonts/roboto/roboto-bold.svg#Roboto") format("svg");
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  src: url("fonts/roboto/roboto-bolditalic.eot");
  src: local("Roboto Bold Italic"), local("Roboto-BoldItalic"), url("fonts/roboto/roboto-bolditalic.eot?#iefix") format("embedded-opentype"), url("fonts/roboto/roboto-bolditalic.woff2") format("woff2"), url("fonts/roboto/roboto-bolditalic.woff") format("woff"), url("fonts/roboto/roboto-bolditalic.ttf") format("truetype"), url("fonts/roboto/roboto-bolditalic.svg#Roboto") format("svg");
}

@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 300;
  src: url("fonts/roboto/robotoslab-light.eot");
  src: local("Roboto Slab Light"), local("RobotoSlab-Light"), url("fonts/roboto/robotoslab-light.eot?#iefix") format("embedded-opentype"), url("fonts/roboto/robotoslab-light.woff2") format("woff2"), url("fonts/roboto/robotoslab-light.woff") format("woff"), url("fonts/roboto/robotoslab-light.ttf") format("truetype"), url("fonts/roboto/robotoslab-light.svg#RobotoSlab") format("svg");
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src: url("fonts/roboto/roboto-black.eot");
  src: local("Roboto Black"), local("Roboto-Black"), url("fonts/roboto/roboto-black.eot?#iefix") format("embedded-opentype"), url("fonts/roboto/roboto-black.woff2") format("woff2"), url("fonts/roboto/roboto-black.woff") format("woff"), url("fonts/roboto/roboto-black.ttf") format("truetype"), url("fonts/roboto/roboto-black.svg#Roboto") format("svg");
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 900;
  src: url("fonts/roboto/roboto-blackitalic.eot");
  src: local("Roboto Black Italic"), local("Roboto-BlackItalic"), url("fonts/roboto/roboto-blackitalic.eot?#iefix") format("embedded-opentype"), url("fonts/roboto/roboto-blackitalic.woff2") format("woff2"), url("fonts/roboto/roboto-blackitalic.woff") format("woff"), url("fonts/roboto/roboto-blackitalic.ttf") format("truetype"), url("fonts/roboto/roboto-blackitalic.svg#Roboto") format("svg");
}

@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 400;
  src: url("fonts/roboto/robotoslab-regular.eot");
  src: local("Roboto Slab Regular"), local("RobotoSlab-Regular"), url("fonts/roboto/robotoslab-regular.eot?#iefix") format("embedded-opentype"), url("fonts/roboto/robotoslab-regular.woff2") format("woff2"), url("fonts/roboto/robotoslab-regular.woff") format("woff"), url("fonts/roboto/robotoslab-regular.ttf") format("truetype"), url("fonts/roboto/robotoslab-regular.svg#RobotoSlab") format("svg");
}

@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 700;
  src: url("fonts/roboto/robotoslab-bold.eot");
  src: local("Roboto Slab Bold"), local("RobotoSlab-Bold"), url("fonts/roboto/robotoslab-bold.eot?#iefix") format("embedded-opentype"), url("fonts/roboto/robotoslab-bold.woff2") format("woff2"), url("fonts/roboto/robotoslab-bold.woff") format("woff"), url("fonts/roboto/robotoslab-bold.ttf") format("truetype"), url("fonts/roboto/robotoslab-bold.svg#RobotoSlab") format("svg");
}

@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 300;
  src: url("fonts/roboto/robotocondensed-light.eot");
  src: local("Roboto Condensed Light"), local("RobotoCondensed-Light"), url("fonts/roboto/robotocondensed-light.eot?#iefix") format("embedded-opentype"), url("fonts/roboto/robotocondensed-light.woff2") format("woff2"), url("fonts/roboto/robotocondensed-light.woff") format("woff"), url("fonts/roboto/robotocondensed-light.ttf") format("truetype"), url("fonts/roboto/robotocondensed-light.svg#RobotoCondensed") format("svg");
}

@font-face {
  font-family: 'Roboto Condensed';
  font-style: italic;
  font-weight: 300;
  src: url("fonts/roboto/robotocondensed-lightitalic.eot");
  src: local("Roboto Condensed Light Italic"), local("RobotoCondensed-LightItalic"), url("fonts/roboto/robotocondensed-lightitalic.eot?#iefix") format("embedded-opentype"), url("fonts/roboto/robotocondensed-lightitalic.woff2") format("woff2"), url("fonts/roboto/robotocondensed-lightitalic.woff") format("woff"), url("fonts/roboto/robotocondensed-lightitalic.ttf") format("truetype"), url("fonts/roboto/robotocondensed-lightitalic.svg#RobotoCondensed") format("svg");
}

@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  src: url("fonts/roboto/robotocondensed-regular.eot");
  src: local("Roboto Condensed"), local("RobotoCondensed-Regular"), url("fonts/roboto/robotocondensed-regular.eot?#iefix") format("embedded-opentype"), url("fonts/roboto/robotocondensed-regular.woff2") format("woff2"), url("fonts/roboto/robotocondensed-regular.woff") format("woff"), url("fonts/roboto/robotocondensed-regular.ttf") format("truetype"), url("fonts/roboto/robotocondensed-regular.svg#RobotoCondensed") format("svg");
}

@font-face {
  font-family: 'Roboto Condensed';
  font-style: italic;
  font-weight: 400;
  src: url("fonts/roboto/robotocondensed-regularitalic.eot");
  src: local("Roboto Condensed Italic"), local("RobotoCondensed-Italic"), url("fonts/roboto/robotocondensed-regularitalic.eot?#iefix") format("embedded-opentype"), url("fonts/roboto/robotocondensed-regularitalic.woff2") format("woff2"), url("fonts/roboto/robotocondensed-regularitalic.woff") format("woff"), url("fonts/roboto/robotocondensed-regularitalic.ttf") format("truetype"), url("fonts/roboto/robotocondensed-regularitalic.svg#RobotoCondensed") format("svg");
}

@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  src: url("fonts/roboto/robotocondensed-bold.eot");
  src: local("Roboto Condensed Bold"), local("RobotoCondensed-Bold"), url("fonts/roboto/robotocondensed-bold.eot?#iefix") format("embedded-opentype"), url("fonts/roboto/robotocondensed-bold.woff2") format("woff2"), url("fonts/roboto/robotocondensed-bold.woff") format("woff"), url("fonts/roboto/robotocondensed-bold.ttf") format("truetype"), url("fonts/roboto/robotocondensed-bold.svg#RobotoCondensed") format("svg");
}

@font-face {
  font-family: 'Roboto Condensed';
  font-style: italic;
  font-weight: 700;
  src: url("fonts/roboto/robotocondensed-bolditalic.eot");
  src: local("Roboto Condensed Bold Italic"), local("RobotoCondensed-BoldItalic"), url("fonts/roboto/robotocondensed-bolditalic.eot?#iefix") format("embedded-opentype"), url("fonts/roboto/robotocondensed-bolditalic.woff2") format("woff2"), url("fonts/roboto/robotocondensed-bolditalic.woff") format("woff"), url("fonts/roboto/robotocondensed-bolditalic.ttf") format("truetype"), url("fonts/roboto/robotocondensed-bolditalic.svg#RobotoCondensed") format("svg");
}

@font-face {
  font-family: 'Triplex Sans';
  src: url("fonts/triplex/triplexsansot-light.eot");
  src: url("fonts/triplex/triplexsansot-light.eot?#iefix") format("embedded-opentype"), url("fonts/triplex/triplexsansot-light.woff2") format("woff2"), url("fonts/triplex/triplexsansot-light.woff") format("woff"), url("fonts/triplex/triplexsansot-light.ttf") format("truetype"), url("fonts/triplex/triplexsansot-light.svg#triplex_sans_otregular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Triplex Sans';
  src: url("fonts/triplex/triplexsansot-bold.eot");
  src: url("fonts/triplex/triplexsansot-bold.eot?#iefix") format("embedded-opentype"), url("fonts/triplex/triplexsansot-bold.woff2") format("woff2"), url("fonts/triplex/triplexsansot-bold.woff") format("woff"), url("fonts/triplex/triplexsansot-bold.ttf") format("truetype"), url("fonts/triplex/triplexsansot-bold.svg#triplex_sans_otbold") format("svg");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Triplex Sans';
  src: url("fonts/triplex/triplexsansot-extrabold.eot");
  src: url("fonts/triplex/triplexsansot-extrabold.eot?#iefix") format("embedded-opentype"), url("fonts/triplex/triplexsansot-extrabold.woff2") format("woff2"), url("fonts/triplex/triplexsansot-extrabold.woff") format("woff"), url("fonts/triplex/triplexsansot-extrabold.ttf") format("truetype"), url("fonts/triplex/triplexsansot-extrabold.svg#triplex_sans_otextrabold") format("svg");
  font-weight: 900;
  font-style: normal;
}

/* ----
   Page Header
   ========================================================================== */
.sticky-wrapper {
  line-heigh: 0;
  display: block;
}

.page-top.stuck {
  background: #fff;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}

.page-header {
  padding-top: 10px;
}

.page-header .logo {
  max-width: 85px;
}

.page-header .secondary {
  padding: 10px 0;
  text-align: center;
}

.page-header .secondary p {
  margin-bottom: 0;
}

.page-header .phone,
.page-header .login {
  display: inline-block;
  line-height: 1em;
}

.page-header .phone {
  color: #0071ba;
  font-family: "Triplex Sans", Georgia, serif;
  font-size: 1.325em;
  font-weight: 700;
  margin-right: 10px;
}

.page-header .phone i {
  color: #90867b;
  margin-right: 3px;
  vertical-align: middle;
}

.page-header .login {
  color: #000;
  float: right;
  line-height: 1em;
  margin-left: 15px;
  padding: 6px 8px 6px 8px;
}

@media screen and (min-width: 41.25em) {
  .page-header .login {
    margin-left: 25px;
  }
}

.page-header .login:hover {
  color: #0071ba;
}

.choose-language {
  float: right;
  margin-left: 10px;
}

.choose-language ul {
  margin-bottom: 0;
  margin-top: 5px;
}

.choose-language ul li {
  border: 1px solid #fff;
  line-height: 10px;
  margin-left: 0;
  opacity: 0.8;
  padding: 5px;
}

.choose-language ul li:hover {
  opacity: 1;
}

.choose-language ul li.current {
  background-color: #d5cd9e;
  border-color: #e0e0e0;
  opacity: 1;
}

.choose-language ul img {
  width: 25px;
}

@media screen and (min-width: 41.25em) {
  .page-header {
    padding: 10px;
  }
  .page-header .logo {
    max-width: 230px;
  }
  .page-header .secondary {
    text-align: right;
  }
}

@media screen and (min-width: 64em) {
  .page-header {
    padding: 50px 0 15px;
  }
  .page-header .phone {
    font-size: 1.5263em;
  }
}

.nav-secondary {
  list-style: none outside none;
  margin: 0;
  text-transform: uppercase;
}

.nav-secondary > li {
  display: inline-block;
  margin-left: 2px;
  margin-right: 2px;
  position: relative;
}

.nav-secondary > li > a {
  color: #555;
  display: inline-block;
  font-size: 0.8em;
  padding: 8px 4px;
}

.nav-secondary > li.active a,
.nav-secondary > li > a:hover {
  background: #0071ba;
  color: #fff;
}

.languages-dropdown {
  float: right;
  position: relative;
}

.languages-dropdown .dropdown-trigger {
  background-color: #90867b;
  color: #fff;
  display: inline-block;
  font-size: 0.8em;
  margin-left: 2px;
  padding: 8px 25px 8px 10px;
  position: relative;
  text-transform: uppercase;
}

@media screen and (min-width: 41.25em) {
  .languages-dropdown .dropdown-trigger {
    font-size: 1em;
    margin-left: 2px;
  }
}

.languages-dropdown .dropdown-trigger:hover, .languages-dropdown .dropdown-trigger.hover {
  background-color: #555;
  color: #fff;
}

.languages-dropdown .dropdown-trigger:before {
  background: url("images/arrow-down.png");
  position: absolute;
  content: '';
  display: block;
  right: 10px;
  top: 15px;
  height: 9px;
  width: 10px;
}

.languages-dropdown .dropdown-pane {
  background-color: #90867b;
  max-width: 100%;
  padding: 0;
  text-align: center;
  z-index: 99999;
}

.languages-dropdown .dropdown-pane ul {
  list-style: none outside none;
  margin: 0;
}

.languages-dropdown .dropdown-pane ul a {
  color: #fff;
  display: block;
  padding: 5px;
}

.languages-dropdown .dropdown-pane ul a:hover {
  background-color: #555;
}

@media screen and (min-width: 41.25em) {
  .nav-secondary > li > a {
    font-size: 1em;
  }
  .nav-secondary .dropdown-trigger {
    padding-right: 35px;
    padding-left: 15px;
  }
  .nav-secondary .dropdown-trigger:before {
    right: 15px;
  }
}

@media screen and (min-width: 64em) {
  .nav-secondary > li > a {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.reveal--schedule-a-visit {
  background: url("images/bg-modal1.png") no-repeat right bottom #fff;
  border: 0 none;
}

@media screen and (min-width: 41.25em) {
  .reveal--schedule-a-visit {
    padding: 45px 140px 45px 45px;
  }
}

/* ----
   Page Navigation
   ========================================================================== */
/* Mobile first layout SmartMenus Core CSS (it's not recommended editing these rules)
   You need this once per page no matter how many menu trees or different themes you use.
-------------------------------------------------------------------------------------------*/
.sm {
  position: relative;
  z-index: 9999;
}

.sm, .sm ul, .sm li {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  line-height: normal;
  direction: ltr;
  text-align: left;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.sm-rtl, .sm-rtl ul, .sm-rtl li {
  direction: rtl;
  text-align: right;
}

.sm > li > h1, .sm > li > h2, .sm > li > h3, .sm > li > h4, .sm > li > h5, .sm > li > h6 {
  margin: 0;
  padding: 0;
}

.sm ul {
  display: none;
}

.sm li, .sm a {
  position: relative;
}

.sm a {
  display: block;
}

.sm a.disabled {
  cursor: not-allowed;
}

.sm:after {
  content: "\00a0";
  display: block;
  height: 0;
  font: 0px/0 serif;
  clear: both;
  visibility: hidden;
  overflow: hidden;
}

.sm, .sm *, .sm *:before, .sm *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

/* Color Scheme Variables */
.sm-clean {
  background: transparent;
}

.sm-clean a {
  padding: 13px 15px;
  /* make room for the toggle button (sub indicator) */
  padding-right: 52px;
  color: #0071ba;
  font-family: "Source Sans Pro", Arial, sans-serif;
  font-size: 15px;
  font-weight: normal;
  line-height: 16px;
  outline: 0 none;
  text-decoration: none;
}

.sm-clean a:hover, .sm-clean a.highlighted {
  color: #fbb524;
  background: #0071ba;
}

.sm-clean a.current {
  color: #fbb524;
}

.sm-clean a.disabled {
  color: #bbbbbb;
}

.sm-clean a span.sub-arrow {
  position: absolute;
  top: 52%;
  margin-top: -17px;
  left: auto;
  right: 4px;
  width: 33px;
  height: 33px;
  overflow: hidden;
  font: bold 15px/33px monospace !important;
  text-align: center;
  text-shadow: none;
  background: rgba(255, 255, 255, 0.5);
}

.sm-clean a.highlighted span.sub-arrow:before {
  display: block;
  content: '-';
}

.sm-clean li {
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.sm-clean > li:first-child {
  border-top: 0;
}

.sm-clean li.parent > a,
.sm-clean li.active a {
  color: #fbb524;
  background: #0071ba;
}

.sm-clean ul {
  background: #0071ba;
}

.sm-clean ul a {
  font-size: 15px;
  border-left: 8px solid transparent;
}

.sm-clean ul ul a,
.sm-clean ul ul a:hover,
.sm-clean ul ul a:focus,
.sm-clean ul ul a:active {
  border-left: 16px solid transparent;
}

.sm-clean ul ul ul a,
.sm-clean ul ul ul a:hover,
.sm-clean ul ul ul a:focus,
.sm-clean ul ul ul a:active {
  border-left: 24px solid transparent;
}

.sm-clean ul ul ul ul a,
.sm-clean ul ul ul ul a:hover,
.sm-clean ul ul ul ul a:focus,
.sm-clean ul ul ul ul a:active {
  border-left: 32px solid transparent;
}

.sm-clean ul ul ul ul ul a,
.sm-clean ul ul ul ul ul a:hover,
.sm-clean ul ul ul ul ul a:focus,
.sm-clean ul ul ul ul ul a:active {
  border-left: 40px solid transparent;
}

@media screen and (min-width: 41.25em) {
  /* Switch to desktop layout
	-----------------------------------------------
	   These transform the menu tree from
	   collapsible to desktop (navbar + dropdowns)
	-----------------------------------------------*/
  /* start... (it's not recommended editing these rules) */
  .sm-clean ul {
    position: absolute;
    width: 12em;
  }
  .sm-clean li {
    float: left;
  }
  .sm-clean.sm-rtl li {
    float: right;
  }
  .sm-clean ul li, .sm-clean.sm-rtl ul li, .sm-clean.sm-vertical li {
    float: none;
  }
  .sm-clean a {
    white-space: nowrap;
  }
  .sm-clean ul a, .sm-clean.sm-vertical a {
    white-space: normal;
  }
  .sm-clean .sm-nowrap > li > a, .sm-clean .sm-nowrap > li > :not(ul) a {
    white-space: nowrap;
  }
  /* ...end */
  .sm-clean a {
    padding: 15px 15px;
  }
  .sm-clean a.has-submenu {
    padding-right: 27px;
  }
  .sm-clean a span.sub-arrow {
    top: 52%;
    margin-top: -2px;
    right: 15px;
    width: 0;
    height: 0;
    border-width: 4px;
    border-style: solid dashed dashed dashed;
    border-color: rgba(255, 255, 255, 0.75) transparent transparent transparent;
    background: transparent;
  }
  .sm-clean a.highlighted span.sub-arrow:before {
    display: none;
  }
  .sm-clean li {
    border-top: 0;
  }
  .sm-clean ul {
    padding: 8px 8px;
    background: #0071ba;
  }
  .sm-clean ul a {
    border: 0 !important;
    padding: 10px 10px;
  }
  .sm-clean ul a, .sm-clean ul a:hover, .sm-clean ul a:focus, .sm-clean ul a:active, .sm-clean ul a.highlighted {
    color: #fff;
  }
  .sm-clean ul a:hover, .sm-clean ul a:focus, .sm-clean ul a:active, .sm-clean ul a.highlighted {
    background: transparent;
    color: #d5cd9e;
  }
  .sm-clean ul a.current {
    color: #D23600;
  }
  .sm-clean ul a.disabled {
    background: #0071ba;
    color: #cccccc;
  }
  .sm-clean ul a.has-submenu {
    padding-right: 10px;
  }
  .sm-clean ul a span.sub-arrow {
    right: 8px;
    top: 50%;
    margin-top: -5px;
    border-width: 5px;
    border-style: dashed dashed dashed solid;
    border-color: transparent transparent transparent rgba(255, 255, 255, 0.75);
  }
  .sm-clean span.scroll-up,
  .sm-clean span.scroll-down {
    position: absolute;
    display: none;
    visibility: hidden;
    overflow: hidden;
    background: #0071ba;
    height: 20px;
  }
  .sm-clean span.scroll-up:hover,
  .sm-clean span.scroll-down:hover {
    background: transparent;
  }
  .sm-clean span.scroll-up:hover span.scroll-up-arrow, .sm-clean span.scroll-up:hover span.scroll-down-arrow {
    border-color: transparent transparent #d5cd9e transparent;
  }
  .sm-clean span.scroll-down:hover span.scroll-down-arrow {
    border-color: #d5cd9e transparent transparent transparent;
  }
  .sm-clean span.scroll-up-arrow, .sm-clean span.scroll-down-arrow {
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -6px;
    width: 0;
    height: 0;
    overflow: hidden;
    border-width: 6px;
    border-style: dashed dashed solid dashed;
    border-color: transparent transparent #fff transparent;
  }
  .sm-clean span.scroll-down-arrow {
    top: 8px;
    border-style: solid dashed dashed dashed;
    border-color: #fff transparent transparent transparent;
  }
  .sm-clean.sm-rtl a.has-submenu {
    padding-right: 15px;
    padding-left: 27px;
  }
  .sm-clean.sm-rtl a span.sub-arrow {
    right: auto;
    left: 15px;
  }
  .sm-clean.sm-rtl.sm-vertical a.has-submenu {
    padding: 10px 15px;
  }
  .sm-clean.sm-rtl.sm-vertical a span.sub-arrow {
    right: auto;
    left: 8px;
    border-style: dashed solid dashed dashed;
    border-color: transparent rgba(255, 255, 255, 0.75) transparent transparent;
  }
  .sm-clean.sm-rtl > li > ul:before {
    left: auto;
    right: 30px;
  }
  .sm-clean.sm-rtl > li > ul:after {
    left: auto;
    right: 30px;
  }
  .sm-clean.sm-rtl ul a.has-submenu {
    padding: 10px 10px !important;
  }
  .sm-clean.sm-rtl ul a span.sub-arrow {
    right: auto;
    left: 8px;
    border-style: dashed solid dashed dashed;
    border-color: transparent rgba(255, 255, 255, 0.75) transparent transparent;
  }
  .sm-clean.sm-vertical {
    padding: 10px 0;
  }
  .sm-clean.sm-vertical a {
    padding: 10px 15px;
  }
  .sm-clean.sm-vertical a:hover, .sm-clean.sm-vertical a:focus, .sm-clean.sm-vertical a:active, .sm-clean.sm-vertical a.highlighted {
    background: #fff;
  }
  .sm-clean.sm-vertical a.disabled {
    background: #eeeeee;
  }
  .sm-clean.sm-vertical a span.sub-arrow {
    right: 8px;
    top: 50%;
    margin-top: -5px;
    border-width: 5px;
    border-style: dashed dashed dashed solid;
    border-color: transparent transparent transparent rgba(255, 255, 255, 0.75);
  }
  .sm-clean.sm-vertical > li > ul:before,
  .sm-clean.sm-vertical > li > ul:after {
    display: none;
  }
  .sm-clean.sm-vertical ul a {
    padding: 10px 10px;
  }
  .sm-clean.sm-vertical ul a:hover, .sm-clean.sm-vertical ul a:focus, .sm-clean.sm-vertical ul a:active, .sm-clean.sm-vertical ul a.highlighted {
    background: transparent;
  }
  .sm-clean.sm-vertical ul a.disabled {
    background: #0071ba;
  }
}

.page-nav {
  background: #fbb524;
}

.main-nav {
  position: relative;
}

.sm-clean ul a {
  color: #fff;
}

@media screen and (min-width: 41.25em) {
  .sm ul {
    max-width: none !important;
    margin: 0 !important;
    left: auto !important;
    /*
		&:before {
			background: $color-primary;
			position: absolute;
			content: '';
			height: 100%;
			width: 100%;
			left: -50%;
			top: 0;
			display: block;
		}

		&:after {
			background: $color-primary;
			position: absolute;
			content: '';
			height: 100%;
			width: 100%;
			right: -50%;
			top: 0;
			display: block;
			z-index: 5;
		}
		*/
  }
  .sm ul li {
    position: relative;
    z-index: 10;
    /*
			&+li:before {
				background: #fff;
				position: absolute;
				content: '';
				display: block;
				height: 15px;
				width: 1px;
				top: 11px;
				z-index: 5;
			}
			*/
  }
  .sm li {
    position: static;
    z-index: 10;
  }
}

@media screen and (min-width: 75em) {
  .sm-clean a {
    font-size: 15px;
    padding-left: 31px;
    padding-right: 31px;
  }
  .sm-clean ul a {
    font-size: 15px;
    padding-left: 17px;
    padding-right: 17px;
  }
}

#main-menu.closed {
  display: none;
}

@media screen and (min-width: 41.25em) {
  #main-menu.closed {
    display: block !important;
  }
}

a.menu-trigger {
  background: #555;
  color: #fff;
  display: block;
  font-size: 20x;
  padding: 5px 0 6px;
  text-align: center;
  text-transform: uppercase;
}

a.search-global-trigger {
  color: #fff;
  display: inline-block;
  font-size: 20px;
  line-height: 36px;
  padding: 0 10px;
  transition: all 250ms ease;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 9999;
}

a.search-global-trigger:hover, a.search-global-trigger.active {
  background: #d5cd9e;
  color: #0071ba;
}

@media screen and (min-width: 41.25em) {
  a.search-global-trigger {
    padding: 5px 15px;
  }
}

.search-global {
  background: #d5cd9e;
  position: absolute;
  right: 0;
  top: 36px;
  z-index: 9999;
  text-align: right;
  display: none;
}

@media screen and (min-width: 41.25em) {
  .search-global {
    top: 46px;
  }
}

.search-global .sg-form {
  overflow: hidden;
  padding: 10px;
}

@media screen and (min-width: 41.25em) {
  .search-global .sg-form {
    padding: 10px 15px;
  }
}

.search-global input[type="text"] {
  width: 70%;
}

.search-global input[type="submit"] {
  width: 25%;
  float: right;
}

/* ----
   Page Body
   ========================================================================== */
.page-section {
  padding-top: 35px;
  padding-bottom: 35px;
}

@media screen and (min-width: 41.25em) {
  .page-section {
    padding-top: 65px;
    padding-bottom: 65px;
  }
}

.page-section.text-indent p:not(.text-center):not(.text-right) {
  text-indent: 35px;
}

.page-intro {
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-color: #cbbda6;
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 160px;
}

@media screen and (min-width: 41.25em) {
  .page-intro {
    height: 260px;
  }
}

@media screen and (min-width: 64em) {
  .page-intro {
    height: 360px;
  }
}

.page-intro .page-intro-inner {
  color: #fff;
  display: table-cell;
  padding: 20px 0;
  position: relative;
  text-align: center;
  vertical-align: middle;
}

@media screen and (min-width: 41.25em) {
  .page-intro .page-intro-inner {
    padding: 50px 0;
  }
}

.page-intro .page-intro-inner .columns *:last-child {
  margin-bottom: 0;
}

.page-intro .page-intro-inner:before {
  background: rgba(0, 0, 0, 0.1);
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

.page-intro .page-intro-title {
  line-height: 1em;
  position: relative;
}

@media screen and (min-width: 41.25em) {
  .page-intro .page-intro-title {
    font-size: 1.9474em;
  }
}

.page-body {
  padding-top: 35px;
  padding-bottom: 35px;
}

@media screen and (min-width: 41.25em) {
  .page-body {
    padding-bottom: 65px;
    padding-top: 0;
  }
  .page-nav + .page-body {
    padding-top: 65px;
  }
}

.page-content-primary {
  margin-bottom: 50px;
}

@media screen and (min-width: 41.25em) {
  .page-content-primary {
    margin-bottom: 0;
  }
}

.site-intro {
  font-size: 1.3em;
}

.site-intro p {
  margin-bottom: 1.5em;
}

@media screen and (min-width: 41.25em) {
  .page-content-wrapped {
    border-top: 0;
    margin-top: 88px;
    padding-top: 0;
  }
}

.sidebar-home {
  font-size: 0.8421em;
}

/* ----
   Page Sidebar
   ========================================================================== */
.page-content-secondary {
  border-top: 1px solid #e0e0e0;
  margin-top: 20px;
  padding-top: 20px;
}

@media screen and (min-width: 41.25em) {
  .page-content-secondary {
    border-top: 0;
    margin-top: 88px;
    padding-top: 0;
  }
}

.sidebar-block,
.sidebar-block-basic {
  margin-bottom: 40px;
}

.sidebar-block h5,
.sidebar-block-basic h5 {
  font-weight: 800;
}

.sidebar-block {
  background-color: #d5cd9e;
  overflow: hidden;
  padding: 20px;
}

.sidebar-block *:last-child {
  margin-bottom: 0;
}

.sidebar-block [type="text"], .sidebar-block [type="password"], .sidebar-block [type="date"], .sidebar-block [type="datetime"], .sidebar-block [type="datetime-local"], .sidebar-block [type="month"], .sidebar-block [type="week"], .sidebar-block [type="email"], .sidebar-block [type="number"], .sidebar-block [type="search"], .sidebar-block [type="tel"], .sidebar-block [type="time"], .sidebar-block [type="url"], .sidebar-block [type="color"], .sidebar-block textarea {
  background-color: #f8f8f2;
}

.sidebar-nav,
.page-breadcrumbs {
  overflow: hidden;
}

.sidebar-nav .breadcrumbs,
.page-breadcrumbs .breadcrumbs {
  line-height: 1.2em;
}

.sidebar-nav .breadcrumbs li,
.page-breadcrumbs .breadcrumbs li {
  display: inline;
  float: none;
  font-size: 0.75em;
}

.sidebar-nav nav,
.page-breadcrumbs nav {
  float: right;
}

@media screen and (min-width: 41.25em) {
  .sidebar-nav {
    min-height: 88px;
  }
}

.page-breadcrumbs {
  padding-top: 25px;
}

.page-breadcrumbs .breadcrumbs {
  border-bottom: 1px solid #e0e0df;
  margin-bottom: 10px;
  padding-bottom: 5px;
}

@media screen and (min-width: 41.25em) {
  .page-breadcrumbs .breadcrumbs {
    float: right;
  }
}

.contact-block {
  background: url("images/brand-line.png") no-repeat left bottom #90867b;
  border-bottom-left-radius: 65px;
  color: #fff;
  padding: 30px;
}

.contact-block label {
  color: #fff;
}

.contact-block label.required:after,
.contact-block label.error {
  color: #f8b332;
}

form[name="contact"] button[type="submit"] {
  background: url("images/brushstroke1.png") no-repeat left top;
  color: #ca3576;
  padding: 20px 24px;
}

form[name="contact"] button[type="submit"]:hover {
  color: #0071ba;
}

.quote-box.social-block h2 {
  color: #fbb524;
  line-height: 1em;
}

.quote-box.social-block .social-list {
  overflow: hidden;
  text-align: center;
}

.quote-box.social-block .social-list li {
  display: inline-block;
}

.quote-box.social-block .social-list li a {
  margin: 0;
}

/* ----
   Page Footer
   ========================================================================== */
.page-footer .pf-primary {
  background: #90867b;
  border-bottom: 4px solid #0071ba;
  color: #fff;
  font-size: 0.7895em;
  line-height: 1.2em;
  padding-top: 10px;
  padding-bottom: 10px;
}

@media screen and (min-width: 41.25em) {
  .page-footer .pf-primary {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.page-footer .pf-primary a {
  color: #f8b332;
}

.page-footer .pf-primary a:hover {
  color: #d5cd9e;
}

.page-footer .pf-primary h6 {
  font-size: 1.25em;
  font-weight: bold;
  text-transform: uppercase;
}

.page-footer .pf-primary ul:last-child,
.page-footer .pf-primary p:last-child {
  margin-bottom: 0;
}

.page-footer .pf-secondary {
  padding: 15px 0;
  text-align: center;
}

.page-footer .pf-secondary p:last-child {
  margin-bottom: 0;
}

.footer-block {
  padding: 25px 0;
}

.footer-list {
  list-style: none outside none;
  margin-left: 0;
}

.footer-list a {
  display: block;
}

.page-footer .social-list.row {
  max-width: 215px;
}

.page-footer .social-list a,
.page-footer .social-list a:hover {
  color: #90867b;
}

/* ----
   Page Slider
   ========================================================================== */
/* ==> Slider Basics & Navigation */
.page-slider {
  overflow: hidden;
  position: relative;
}

.page-slider:before {
  background: rgba(51, 51, 51, 0.2);
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

.page-slider .flexslider {
  background-color: #fefefe;
}

.page-slider .flex-direction-nav .flex-prev,
.page-slider .flex-direction-nav .flex-next {
  top: 60px;
}

.page-slider .flex-control-nav.flex-control-paging {
  bottom: 8px;
  z-index: 5;
}

@media screen and (min-width: 41.25em) {
  .page-slider .flex-control-nav.flex-control-paging {
    bottom: 30px;
  }
}

.page-slider .flex-control-nav.flex-control-paging a {
  background-color: #0071ba;
  border: 3px solid #0071ba;
}

.page-slider .flex-control-nav.flex-control-paging a.flex-active {
  background-color: #fff;
}

.page-slider .slide {
  position: relative;
}

@media screen and (min-width: 41.25em) {
  .page-slider,
  .page-slider .flexslider,
  .page-slider .slides,
  .page-slider .slides li,
  .page-slider .slide-image {
    height: 100%;
    min-height: 350px;
  }
  .page-slider .flex-direction-nav .flex-prev,
  .page-slider .flex-direction-nav .flex-next {
    top: 50%;
  }
  .page-slider .slide {
    height: 100%;
  }
}

@media screen and (min-width: 64em) {
  .page-slider,
  .page-slider .flexslider,
  .page-slider .slides,
  .page-slider .slides li,
  .page-slider .slide-image {
    min-height: 440px;
  }
}

/* ==> Slide Image */
.page-slider .slide-image {
  background-size: cover;
  background-position: center center;
  display: block;
  margin: 0;
  position: relative;
  height: 120px;
  width: 100%;
}

/* ==> Slide Caption */
@media screen and (min-width: 41.25em) {
  .page-slider .slide-caption {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }
}

.page-slider .slide-caption > .slide-caption-content {
  display: table;
  height: 100%;
  width: 100%;
}

.page-slider .slide-caption > .slide-caption-content .inner {
  display: table-cell;
  padding: 1em 0;
  vertical-align: middle;
}

@media screen and (min-width: 64em) {
  .page-slider .slide-caption > .slide-caption-content .inner {
    padding-top: 3.75em;
    padding-bottom: 2em;
  }
}

.page-slider .slide-caption > .slide-caption-content .inner.valign-bottom {
  vertical-align: bottom;
}

.slide-blurb-overlay {
  background: rgba(0, 113, 186, 0.8);
  color: #fff;
  padding: 30px;
}

.slide-blurb-overlay a {
  color: #fff;
  text-decoration: underline;
}

.slide-blurb-overlay a:hover {
  color: #ffae00;
}

/* ==> Slide Blurb */
.page-slider .slide-blurb {
  background-color: rgba(144, 134, 123, 0.7);
  border-bottom-left-radius: 65px;
  color: #fff;
  overflow: hidden;
  position: relative;
}

.page-slider .slide-blurb:before {
  background: url("images/brand-line.png") no-repeat left bottom;
  display: block;
  position: absolute;
  content: '';
  height: 178px;
  width: 348px;
  bottom: 0;
  left: 0;
  border-bottom-left-radius: 50px;
}

.page-slider .slide-blurb .slide-blurb-inner {
  padding: 30px;
  position: relative;
}

@media screen and (min-width: 64em) {
  .page-slider .slide-blurb .slide-blurb-inner {
    padding: 20px;
  }
}

.page-slider .slide-blurb h4 {
  font-weight: 700;
  line-height: 1.25em;
  margin-bottom: 0.5em;
}

@media screen and (min-width: 41.25em) {
  .page-slider .slide-blurb h4 {
    font-size: 1.75em;
  }
}

/* ----
   Blog
   ========================================================================== */
.blog-post-image {
  margin-bottom: 20px;
}

.blog-post-meta ul {
  @extends .inline-list;
  list-style: none outside none;
  margin-left: 0;
  overflow: hidden;
}

.blog-post-meta ul li {
  color: #999;
  display: block;
  float: left;
  padding-right: 10px;
  position: relative;
}

.blog-post-meta ul li:first-child {
  float: right;
  padding-right: 0;
}

.blog-post-meta ul li:first-child:before {
  display: none;
}

.blog-post-meta ul li + li + li {
  padding-left: 10px;
}

.blog-post-meta ul li + li + li:before {
  background: #999;
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 5px;
  height: 15px;
  width: 1px;
}

.blog-post-meta ul li a {
  color: #999;
}

.blog-post-meta ul li a:hover {
  color: #0071ba;
}

.blog-post-share {
  border-bottom: 1px dashed #cacaca;
}

.blog-post-nav {
  border-bottom: 1px solid #cacaca;
  margin-bottom: 50px;
  padding: 10px 0;
}

.blog-post-nav a {
  color: #444;
  display: block;
  font-style: italic;
}

.blog-post-nav a:hover {
  color: #0071ba;
}

.blog-post-nav .blog-post-prev,
.blog-post-nav .blog-post-next {
  margin: 10px 0;
}

.blog-post-nav .blog-post-prev {
  background: url("images/arrow-prev.png") no-repeat left 3px;
  padding-left: 40px;
}

.blog-post-nav .blog-post-next {
  background: url("images/arrow-next.png") no-repeat right 3px;
  padding-right: 40px;
}

.blog-post-comment textarea {
  min-height: 200px;
}

.blog-post-blurb {
  margin-top: 15px;
  margin-bottom: 15px;
}

.bpb-figure img {
  width: 100%;
}

.bpb-content {
  border: 1px solid #e0e0df;
  padding: 15px 20px;
}

.bpb-title {
  margin-bottom: 20px;
}

@media screen and (min-width: 41.25em) {
  .bpb-title {
    min-height: 60px;
  }
}

.bpb-title a {
  color: #444;
}

.bpb-title a:hover {
  color: #0071ba;
}

/* ----
   Page Modules
   ========================================================================== */
/* ==> Page Form */
.page-form {
  background-color: #90867b;
  border-bottom: 4px solid #0071ba;
  color: #fff;
  padding: 20px 0;
  position: relative;
}

@media screen and (min-width: 64em) {
  .page-form {
    margin-top: 0;
  }
}

.page-form .page-form-inner *:last-child {
  margin-bottom: 0;
}

.page-form > .row {
  position: relative;
  z-index: 10;
}

.page-form .section-title {
  font-family: "din-condensed-web", "Source Sans Pro", Arial, sans-serif;
  /*	font-style: italic;*/
  font-weight: 300;
  margin-bottom: 0.5em;
  text-align: center;
}

/* ==> Content Blurbs */
.content-blurb {
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;
}

@media screen and (min-width: 41.25em) {
  .content-blurb {
    margin-bottom: 25px;
  }
}

.content-blurb .cb-title {
  background: #0071ba;
  display: table;
  height: 60px;
  font-weight: 700;
  font-size: 1.5em;
  line-height: 1.25em;
  margin-bottom: 0;
  width: 100%;
}

.content-blurb .cb-title a {
  color: #fff;
  display: table-cell;
  line-height: 1.2em;
  padding: 10px 5px;
  vertical-align: middle;
}

.content-blurb .cb-title a:hover {
  background: #676057;
}

.content-blurb .cb-figure {
  display: block;
  margin-bottom: 1rem;
  overflow: hidden;
}

.content-blurb .cb-figure figure {
  display: inline-block;
  max-width: 100%;
  position: relative;
  width: 100%;
  height: 200px;
  overflow: hidden;
  /*
			&:before {
				background: url('images/logo-overlay.png') no-repeat left top;
				position: absolute;
				content: '';
				height: 101px;
				width: 227px;
				left: 20px;
				bottom: 20px;
			}
			*/
}

.content-blurb .cb-figure img {
  width: 100%;
}

.content-blurb .cb-desc {
  line-height: 1.5625em;
}

.content-blurb .cb-desc a {
  color: #fff;
}

/* ==> Company Facts */
.company-facts {
  list-style: none outside none;
  margin: 0;
}

.company-facts .fact {
  border: 1px solid #e8e8e8;
  border-width: 1px 0 0 0;
  padding: 15px 0;
  margin: 0 8px;
  text-align: center;
}

.company-facts > .columns:first-child .fact {
  border-top: 0;
}

.company-facts .fact-number {
  color: #f8b332;
  display: block;
  font-family: "din-condensed-web", "Source Sans Pro", Arial, sans-serif;
  font-size: 3.4375em;
  font-weight: 400;
  line-height: 1em;
  letter-spacing: -2px;
  position: relative;
  white-space: nowrap;
}

.company-facts .fact-number span {
  display: inline;
}

.company-facts .fact-title,
.company-facts .fact-desc {
  color: #555;
  font-size: 15px;
  text-transform: uppercase;
}

.company-facts .fact-title {
  display: block;
  line-height: 1.2em;
  position: relative;
}

@media screen and (min-width: 41.25em) {
  .company-facts > .columns:first-child .fact {
    border-left: 0;
  }
  .company-facts > .columns:last-child .fact {
    border-right: 0;
  }
  .company-facts .fact {
    border-width: 0 1px;
  }
  .company-facts .fact-number {
    font-size: 4.2105em;
  }
  .company-facts .fact-desc {
    display: block;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (min-width: 64em) {
  .company-facts .fact-number {
    font-size: 6.3158em;
  }
}

/* ==> Team member blurb */
.team-member-blurb {
  background-color: #f2f2f2;
  /* Old browsers */
  box-shadow: -8px 10px 8px 0 rgba(157, 148, 140, 0.4);
  margin: 30px 0;
  padding: 12px;
  position: relative;
  -moz-transition: -moz-transform .15s linear;
  -o-transition: -o-transform .15s linear;
  -webkit-transition: -webkit-transform .15s linear;
}

.team-member-blurb:before {
  background: url("images/tm-figure-pin.png") no-repeat left top;
  content: '';
  display: block;
  position: absolute;
  left: 50%;
  margin-left: -8px;
  top: -9px;
  height: 17px;
  width: 17px;
}

.team-member-blurb figure {
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 145px;
  margin-bottom: 15px;
  text-align: center;
  position: relative;
}

.team-member-blurb .tm-title {
  color: #4e4e4e;
  display: block;
  font-size: 0.5263em;
  line-height: 1.3em;
  margin-bottom: 0.5em;
}

.team-member-blurb .tm-name h5 {
  font-size: 1.0526em;
  line-height: 1.1em;
}

.team-member-blurb .tm-name a {
  color: #f25fb6;
}

.team-member-blurb .tm-content *:last-child {
  margin-bottom: 0;
}

.team-member-blurbs .column:nth-child(even) {
  -o-transform: rotate(4deg);
  -webkit-transform: rotate(4deg);
  -moz-transform: rotate(4deg);
  position: relative;
  top: 5px;
}

.team-member-blurbs .column:nth-child(even) .tm-name a {
  color: #6da642;
}

.team-member-blurbs .column:nth-child(3n) {
  -o-transform: rotate(-3deg);
  -webkit-transform: rotate(-3deg);
  -moz-transform: rotate(-3deg);
  position: relative;
  top: -5px;
}

.team-member-blurbs .column:nth-child(3n) .tm-name a {
  color: #2570b8;
}

.team-member-blurbs .column:nth-child(5n) {
  -o-transform: rotate(5deg);
  -webkit-transform: rotate(5deg);
  -moz-transform: rotate(5deg);
  position: relative;
  top: -10px;
}

.team-member-blurbs .column:nth-child(5n) .tm-name a {
  color: #6da642;
}

.team-member-blurb:hover,
.team-member-blurb:focus {
  -webkit-transform: scale(1.15) rotate(3deg);
  -moz-transform: scale(1.15) rotate(3deg);
  -o-transform: scale(1.15) rotate(3deg);
  position: relative;
  z-index: 5;
}

/* ==> Polaroid Box */
.polaroid-box-item {
  padding: 30px 0;
}

.polaroid-box-item a {
  display: block;
}

.polaroid-box {
  background: url("images/bg-polaroid-box.jpg") no-repeat 15px center #f2f2f2;
  box-shadow: -8px 10px 8px 0 rgba(115, 105, 95, 0.4);
  margin: 10px auto 30px;
  padding: 12px;
  max-width: 200px;
  position: relative;
  -moz-transition: -moz-transform .15s linear;
  -o-transition: -o-transform .15s linear;
  -webkit-transition: -webkit-transform .15s linear;
}

.polaroid-box:before {
  background: url("images/tm-figure-pin.png") no-repeat left top;
  content: '';
  display: block;
  position: absolute;
  left: 50%;
  margin-left: -8px;
  top: -9px;
  height: 17px;
  width: 17px;
}

.polaroid-box a {
  display: block;
}

.polaroid-box .polaroid-box-image {
  margin-bottom: 5px;
}

.polaroid-box .polaroid-box-title p {
  margin-bottom: 0;
}

.polaroid-box .polaroid-box-title-primary {
  color: #0071ba;
  font-family: "Triplex Sans", Georgia, serif;
  font-weight: 700;
  line-height: 1.1em;
}

.polaroid-box .polaroid-box-title-secondary {
  color: #444;
  font-size: 10px;
  line-height: 1.1em;
  margin-top: 5px;
}

.polaroid-box-list--early-learning .polaroid-box-s1 {
  -moz-transform: rotate(5deg);
  -o-transform: rotate(5deg);
  -webkit-transform: rotate(5deg);
}

.polaroid-box-list--early-learning .polaroid-box-s2 {
  -moz-transform: rotate(2deg);
  -o-transform: rotate(2deg);
  -webkit-transform: rotate(2deg);
}

.polaroid-box-list--early-learning .polaroid-box-s3 {
  -moz-transform: rotate(-2deg);
  -o-transform: rotate(-2deg);
  -webkit-transform: rotate(-2deg);
}

.polaroid-box-list--early-learning .polaroid-box-s4 {
  -moz-transform: rotate(-5deg);
  -o-transform: rotate(-5deg);
  -webkit-transform: rotate(-5deg);
}

.polaroid-box-list--team-members .polaroid-box-s1,
.polaroid-box-list--team-members .polaroid-box-s4,
.polaroid-box-items .polaroid-box-s1,
.polaroid-box-items .polaroid-box-s4 {
  -moz-transform: rotate(-4deg);
  -o-transform: rotate(-4deg);
  -webkit-transform: rotate(-4deg);
}

.polaroid-box-list--team-members .polaroid-box-s2,
.polaroid-box-list--team-members .polaroid-box-s7,
.polaroid-box-items .polaroid-box-s2,
.polaroid-box-items .polaroid-box-s7 {
  -moz-transform: rotate(3deg);
  -o-transform: rotate(3deg);
  -webkit-transform: rotate(3deg);
}

.polaroid-box-list--team-members .polaroid-box-s3,
.polaroid-box-list--team-members .polaroid-box-s5,
.polaroid-box-items .polaroid-box-s3,
.polaroid-box-items .polaroid-box-s5 {
  -moz-transform: rotate(4deg);
  -o-transform: rotate(4deg);
  -webkit-transform: rotate(4deg);
}

.polaroid-box-list--team-members .polaroid-box-s6,
.polaroid-box-list--team-members .polaroid-box-s8,
.polaroid-box-items .polaroid-box-s6,
.polaroid-box-items .polaroid-box-s8 {
  -moz-transform: rotate(1deg);
  -o-transform: rotate(1deg);
  -webkit-transform: rotate(1deg);
}

.polaroid-box-list--team-members .polaroid-box-s1 .polaroid-box-title-primary,
.polaroid-box-list--team-members .polaroid-box-s4 .polaroid-box-title-primary,
.polaroid-box-list--team-members .polaroid-box-s6 .polaroid-box-title-primary,
.polaroid-box-items .polaroid-box-s1 .polaroid-box-title-primary,
.polaroid-box-items .polaroid-box-s4 .polaroid-box-title-primary,
.polaroid-box-items .polaroid-box-s6 .polaroid-box-title-primary {
  color: #f25fb6;
}

.polaroid-box-list--team-members .polaroid-box-s2 .polaroid-box-title-primary,
.polaroid-box-list--team-members .polaroid-box-s7 .polaroid-box-title-primary,
.polaroid-box-items .polaroid-box-s2 .polaroid-box-title-primary,
.polaroid-box-items .polaroid-box-s7 .polaroid-box-title-primary {
  color: #6ca642;
}

.polaroid-box-list--early-learning .polaroid-box:hover,
.polaroid-box-list--team-members .polaroid-box:hover {
  -moz-transform: scale(1.1) rotate(0deg);
  -o-transform: scale(1.1) rotate(0deg);
  -webkit-transform: scale(1.1) rotate(0deg);
}

@media screen and (min-width: 41.25em) {
  .polaroid-box .polaroid-box-title-primary {
    font-size: 1.2105em;
  }
}

/* ==> Accordion panel */
.accordion-panels .panel-list-title {
  font-size: 1em;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.accordion-panel {
  padding: 10px 0;
  padding-left: 50px;
  position: relative;
}

.accordion-panel p {
  margin-bottom: 0.5em;
}

.accordion-panel p:last-child {
  margin-bottom: 0;
}

.accordion-panel .trigger {
  color: #444;
  display: block;
  font-family: "Source Sans Pro", Arial, sans-serif;
  padding-top: 8px;
  position: relative;
}

.accordion-panel .trigger:before {
  background: url("images/accordion-open.png") no-repeat left top;
  content: '';
  line-height: 30px;
  font-size: 20px;
  display: block;
  text-align: center;
  position: absolute;
  height: 38px;
  width: 41px;
  left: -50px;
  top: 0;
}

.accordion-panel .expanded {
  display: none;
}

.accordion-panel.open .trigger:before {
  background: url("images/accordion-close.png") no-repeat left top;
}

/* ==> CTA panel */
.cta-panel {
  background-color: #90867b;
  border-bottom: 5px solid #0071ba;
  box-shadow: 0 -4px 0 0px #fff inset;
  color: #fff;
  font-size: 1.3125em;
  font-weight: bold;
  margin-bottom: 20px;
  padding: 30px;
  text-align: center;
}

.cta-panel *:last-child {
  margin-bottom: 0;
}

.cta-panel a {
  color: #fff;
}

.cta-panel a:hover {
  color: #eee;
}

/* ==> Community Map */
.communitymap {
  margin-bottom: 30px;
  position: relative;
}

.communitymap .communitymap-components {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.communitymap .cc-label {
  font-size: 11px;
  position: absolute;
  top: 0;
  min-height: 20px;
  min-width: 20px;
}

@media screen and (min-width: 64em) {
  .communitymap .cc-label {
    display: block;
    font-size: 14px;
    min-height: 100px;
    width: 235px;
  }
}

.communitymap .cc1 {
  top: 13%;
  left: 10%;
}

.communitymap .cc2 {
  top: 67%;
  left: 16%;
}

.communitymap .cc3 {
  top: 42%;
  left: 20%;
}

.communitymap .cc4 {
  top: 69%;
  left: 50%;
}

.communitymap .cc5 {
  top: 40%;
  left: 49%;
}

@media screen and (min-width: 50em) {
  .communitymap .cc1 {
    left: 1%;
  }
  .communitymap .cc2 {
    left: 2%;
  }
  .communitymap .cc3 {
    left: 5%;
  }
  .communitymap .cc5 {
    left: 53%;
  }
}

@media screen and (min-width: 64em) {
  .communitymap .cc3 {
    left: 14%;
  }
  .communitymap .cc5 {
    left: 63%;
  }
}

.communitymap .cc-label-trigger {
  background-color: #0071ba;
  border-radius: 2px;
  color: #fff;
  display: inline-block;
  font-size: 11px;
  line-height: 1em;
  height: 17px;
  width: 17px;
  text-align: center;
  padding: 3px 0;
  position: absolute;
  left: 0;
  top: 0;
}

@media screen and (min-width: 50em) {
  .communitymap .cc-label-trigger {
    display: none;
  }
}

.communitymap .cc-label-trigger.active {
  background-color: #f8b332;
  z-index: 15;
}

.communitymap .cc-label-table {
  background: url("images/brand-line-s1.png") no-repeat left bottom rgba(255, 255, 255, 0.8);
  border-bottom-left-radius: 34px;
  display: none;
  position: relative;
  width: 200px;
  z-index: 10;
  transition: all 200ms ease-out;
}

@media screen and (min-width: 50em) {
  .communitymap .cc-label-table {
    display: table;
  }
}

@media screen and (min-width: 64em) {
  .communitymap .cc-label-table {
    height: 110px;
    width: 250px;
  }
}

.communitymap .cc-label-table:hover, .communitymap .cc-label-table.flex-active {
  background-color: #0071ba;
  color: #fff;
}

.communitymap .cc-label-table.visible {
  display: table;
}

.communitymap .cc-label-cell {
  display: table-cell;
  vertical-align: middle;
  padding: 10px 5px;
}

.communitymap .cc-label-cell:first-child {
  padding-left: 15px;
}

.communitymap .cc-label-cell:last-child {
  padding-right: 10px;
}

.communitymap .value {
  font-size: 1.8181em;
  font-weight: 900;
  line-height: 1em;
}

@media screen and (min-width: 64em) {
  .communitymap .value {
    font-size: 2.8571em;
  }
}

/* ==> Community Map Testimonials */
@media screen and (min-width: 41.25em) {
  .community-testimonials-title {
    margin-bottom: 53px;
  }
}

.community-testimonial {
  background-color: #eee;
  display: table !important;
  width: 100%;
}

@media screen and (min-width: 75em) {
  .community-testimonial {
    height: 686px;
  }
}

.community-testimonial .ct-row {
  display: table-row;
}

.community-testimonial .ct-image,
.community-testimonial .ct-quote,
.community-testimonial .ct-desc {
  display: table-cell;
}

.community-testimonial .ct-image {
  height: 100px;
}

.community-testimonial .ct-image img {
  width: 100%;
}

.community-testimonial .ct-quote,
.community-testimonial .ct-desc {
  padding: 10px;
  vertical-align: middle;
}

@media screen and (min-width: 64em) {
  .community-testimonial .ct-quote,
  .community-testimonial .ct-desc {
    padding: 20px;
  }
}

.community-testimonial .ct-quote p:last-child,
.community-testimonial .ct-desc p:last-child {
  margin-bottom: 0;
}

.community-testimonial .ct-quote {
  background-color: #0071ba;
  color: #fff;
  font-style: italic;
  font-weight: 300;
  text-align: center;
}

@media screen and (min-width: 64em) {
  .community-testimonial .ct-quote {
    font-size: 1.1875em;
  }
}

.community-testimonial .ct-desc {
  background-color: #90867b;
  color: #fff;
  font-family: "din-condensed-web", "Source Sans Pro", Arial, sans-serif;
  font-size: 1.5625em;
  line-height: 1.2em;
  text-align: center;
}

@media screen and (min-width: 64em) {
  .community-testimonial .ct-desc {
    font-size: 2.1875em;
  }
}

.community-testimonial.flex-active-slide {
  display: table !important;
}

/* ==> Pagination */
.pagination-wrap {
  background-color: #0071ba;
  margin-bottom: 1em;
}

.pagination-wrap .pagination {
  margin-bottom: 0;
}

.pagination-wrap .pagination li.pagination-previous,
.pagination-wrap .pagination li.pagination-next {
  background-color: #90867b;
  padding: 8px 10px;
}

.pagination-wrap .pagination li.pagination-previous a,
.pagination-wrap .pagination li.pagination-next a {
  margin-top: 0;
  margin-bottom: 0;
}

.pagination-wrap .pagination li.pagination-previous {
  float: left;
}

.pagination-wrap .pagination li.pagination-next {
  float: right;
  margin-right: 0;
}

.pagination-wrap .pagination li.disabled {
  padding-top: 11px;
  padding-bottom: 11px;
}

.pagination-wrap .pagination a,
.pagination-wrap .pagination button {
  color: #fff;
  margin-top: 8px;
  margin-bottom: 8px;
}

.pagination-wrap .pagination a:hover,
.pagination-wrap .pagination button:hover {
  background-color: #555;
}

/* ==> Testimonial */
.testimonial {
  background: url("images/brand-line.png") no-repeat left bottom #d5cd9e;
  border-bottom-left-radius: 65px;
}

.testimonial .testimonial-figure {
  text-align: center;
}

.testimonial .testimonial-figure img {
  width: 100%;
}

.testimonial .testimonial-content {
  border: 0;
  color: #444;
  padding: 45px 30px;
  position: relative;
}

.testimonial .testimonial-content:before {
  background: url("images/icon-quote.png") no-repeat left top;
  content: '';
  display: block;
  position: absolute;
  left: 30px;
  top: 23px;
  height: 41px;
  width: 29px;
}

.testimonial .testimonial-content p {
  text-indent: 35px;
}

.testimonial .testimonial-content cite {
  color: #444;
  font-size: 1em;
  text-align: right;
}

/* ==> Social List */
.social-list {
  list-style: none outside none;
  margin-left: 0;
  margin-bottom: 10px;
}

.social-list .column {
  padding-left: 3px;
  padding-right: 3px;
}

.social-list a {
  color: #fff;
  display: inline-block;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.social-list a .fa {
  background: #0071ba;
  border-radius: 50%;
  font-size: 20px;
  color: #fff;
  line-height: 40px;
  height: 40px;
  width: 40px;
  vertical-align: middle;
}

.social-list a:hover .fa {
  background: #f25fb6;
}

.social-list-small a .fa {
  font-size: 18px;
  line-height: 36px;
  height: 36px;
  width: 36px;
}

.social-list-light a .fa {
  background: #fff;
  color: #0071ba;
}

.social-list-light a:hover .fa {
  background: #fff;
  color: #fbb524;
}

.social-list-transparent a .fa {
  color: #0071ba;
  background: rgba(255, 255, 255, 0.6);
}

.social-list-transparent a:hover .fa {
  color: #0071ba;
  background: #fff;
}

.social-list-dark a .fa {
  background: #90867b;
  color: #fff;
}

.social-list-float {
  margin-left: -5px;
  margin-right: -5px;
  overflow: hidden;
}

.social-list-float li {
  float: left;
  margin-left: 5px;
  margin-right: 5px;
}

/* ==> Event Items */
.event-items .event-item {
  border: 1px solid #c7c7c7;
  margin-bottom: 30px;
  padding: 15px;
}

.event-items .event-item figure {
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 150px;
  margin-bottom: 10px;
}

.event-items .event-item header {
  margin-bottom: 15px;
}

.event-items .event-item h4 {
  border-bottom: 1px solid #c7c7c7;
  margin-bottom: 5px;
  padding-bottom: 8px;
}

.event-items .event-item h4 a {
  color: #444;
  display: block;
  line-height: 1.25em;
}

.event-items .event-item h4 a:hover {
  color: #0071ba;
}

.event-items .event-item .event-item-meta ul {
  list-style: none outside none;
  margin: 0;
}

.event-items .event-item .event-item-meta ul li {
  color: #999;
  font-style: italic;
}

.event-items .event-item .event-item-content {
  overflow: hidden;
}

.event-items .button {
  padding-left: 0.5em;
  padding-right: 0.5em;
}

.event-item-date {
  background: #0071ba;
  color: #fff;
  float: left;
  margin: 0 15px 10px 0;
  width: 90px;
}

.event-item-date span {
  display: block;
  text-align: center;
}

.event-item-date .day {
  font-size: 69px;
  font-weight: 600;
  line-height: 1em;
  padding: 0.2em 0;
}

.event-item-date .date {
  background: rgba(0, 0, 0, 0.2);
  font-family: "din-condensed-web", "Source Sans Pro", Arial, sans-serif;
  font-size: 16px;
  padding: 5px 0;
  text-transform: uppercase;
}

.event-details-panel {
  background: #e8e6e4;
  padding: 40px 15px;
  text-align: center;
  margin-top: 35px;
  margin-bottom: 35px;
}

.event-details-panel strong {
  color: #0071ba;
  display: block;
  font-family: "Triplex Sans", Georgia, serif;
  font-size: 1.6em;
  line-height: 1.2em;
}

.event-details-panel p:last-child {
  margin-bottom: 0;
}

.event-featured-image {
  position: relative;
}

.event-featured-image .event-item-date {
  position: absolute;
  left: 0;
  top: 0;
}

.event-featured-image figure {
  height: 400px;
  width: 100%;
  margin-bottom: 30px;
}

.event-featured-image figure img {
  height: 100%;
  object-fit: cover;
}

@media screen and (min-width: 41.25em) {
  .event-featured-image figure {
    height: 580px;
  }
}

.xdsoft_datetimepicker .xdsoft_calendar .event-day {
  background-image: url("images/brandicon-16x16.png");
  background-repeat: no-repeat;
  background-position: left 1px bottom 2px;
  background-color: #f5f5f5;
  font-family: "Triplex Sans", Georgia, serif;
  font-weight: 700;
  font-size: 15px;
}

.xdsoft_datetimepicker .xdsoft_calendar .event-day,
.xdsoft_datetimepicker .xdsoft_calendar .event-day.xdsoft_disabled:hover {
  color: #0071ba !important;
}

.xdsoft_datetimepicker .xdsoft_calendar .event-day:hover {
  background-color: #fff !important;
  color: #f8b332 !important;
}

.xdsoft_datetimepicker .xdsoft_calendar .event-day.xdsoft_current {
  background-color: #0071ba !important;
  color: #fff !important;
}

/* ==> Event Calendar  */
.event-calendar-navigation {
  padding: 0 15px;
}

.event-calendar-navigation ul {
  font-family: "Triplex Sans", Georgia, serif;
  list-style: none outside none;
  text-align: right;
}

.event-calendar-navigation li {
  padding: 2px 0;
}

.event-calendar-navigation h5 {
  margin-bottom: 0;
  margin-top: 20px;
}

.event-calendar-navigation a {
  display: inline-block;
}

.event-calendar-navigation .active a {
  position: relative;
}

.event-calendar-navigation .active a:before {
  background: url("images/bg-branded-title.png") no-repeat left top transparent;
  background-size: 100% 5px;
  content: '';
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  height: 5px;
  width: 100%;
}

.event-calendar-navigation-previous,
.event-calendar-navigation-next {
  color: #fff;
  text-align: center;
  position: absolute;
  height: 100%;
  width: 50px;
  top: 0;
}

.event-calendar-navigation-previous:hover,
.event-calendar-navigation-next:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.event-calendar-navigation-previous {
  background: url("images/arrow-left-white.png") no-repeat center center;
  left: 0;
}

.event-calendar-navigation-next {
  background: url("images/arrow-right-white.png") no-repeat center center;
  right: 0;
}

.event-calendar-table {
  border-color: #6ca642;
  margin-bottom: 50px;
}

.event-calendar-table tbody tr:nth-child(2n) {
  background: transparent;
}

.event-calendar-table th {
  position: relative;
}

.event-calendar-table th, .event-calendar-table td {
  border: 1px solid #6ca642;
  padding: 10px;
  vertical-align: top;
}

.event-calendar-table th {
  background: #6ca642;
  color: #fff;
  font-family: "Triplex Sans", Georgia, serif;
  font-weight: 800;
  line-height: 1.2;
  text-transform: uppercase;
}

.event-calendar-table .event-calendar-day {
  font-weight: 700;
  text-align: center;
  width: 200px;
}

.event-calendar-table a {
  color: #444;
}

.event-calendar-table p {
  margin-bottom: 3px;
}

.event-calendar-featured-event {
  border: 1px solid #6ca642;
  padding: 20px;
  margin-bottom: 15px;
}

.event-item-description h4 {
  margin-bottom: 5px;
}

.event-item-description h4 a {
  display: block;
  line-height: 1.1em;
}

@media screen and (min-width: 41.25em) {
  .event-calendar-navigation {
    font-size: 1.5789em;
    font-weight: 700;
  }
  .event-calendar-table th, .event-calendar-table td {
    padding: 20px;
  }
}

@media screen and (min-width: 64em) {
  .event-calendar-navigation {
    border-right: 1px solid #ccc;
    padding-right: 40px;
    max-width: 300px;
  }
}

/* Book Event Form */
form[name="bookevent"] button[type="submit"] {
  display: inline-block;
  text-align: center;
  line-height: 1;
  cursor: pointer;
  -webkit-appearance: none;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  vertical-align: middle;
  border: 1px solid transparent;
  border-radius: 0;
  padding: 0.85em 1em;
  margin: 0;
  font-size: 0.9rem;
  background-color: #0071ba;
  color: #fefefe;
  text-transform: uppercase;
  font-weight: 700;
  margin-top: 20px;
}

[data-whatinput='mouse'] form[name="bookevent"] button[type="submit"] {
  outline: 0;
}

form[name="bookevent"] button[type="submit"]:hover, form[name="bookevent"] button[type="submit"]:focus {
  background-color: #00609e;
  color: #fefefe;
}

/* ==> Headings & Titles */
.section-title {
  margin-bottom: 0.75em;
  padding-left: 50px;
  padding-right: 50px;
  font-weight: 900;
}

.section-title span {
  display: inline-block;
  position: relative;
}

.section-title span:before,
.section-title span:after {
  background-color: #d5cd9e;
  position: absolute;
  content: '';
  display: block;
  height: 6px;
  width: 40px;
  left: -50px;
  top: 50%;
  margin-top: -3px;
}

.section-title span:after {
  left: auto;
  right: -50px;
}

@media screen and (min-width: 41.25em) {
  .section-title {
    padding-left: 80px;
    padding-right: 80px;
  }
  .section-title span:before,
  .section-title span:after {
    width: 80px;
    left: -95px;
  }
  .section-title span:after {
    right: -95px;
    left: auto;
  }
}

.section-title.st1 span:before,
.section-title.st1 span:after {
  background-color: #f8b332;
}

.section-title.st2 span:before,
.section-title.st2 span:after {
  background-color: #fff;
}

.section-title.st3 span:before,
.section-title.st3 span:after {
  background-color: #0071ba;
}

.page-content-primary h2,
.branded-title,
.branded-title-plain {
  color: #0071ba;
  font-weight: 700;
  letter-spacing: -1px;
  line-height: 1.1em;
  margin-bottom: 35px;
  padding-bottom: 15px;
  position: relative;
}

.page-content-primary h2:after,
.branded-title:after,
.branded-title-plain:after {
  background-color: #d5cd9e;
  border-radius: 8px;
  position: absolute;
  content: '';
  display: block;
  height: 8px;
  width: 40px;
  left: 0;
  bottom: 0;
}

@media screen and (min-width: 41.25em) {
  .page-content-primary h2:after,
  .branded-title:after,
  .branded-title-plain:after {
    width: 100px;
  }
}

.branded-title-plain:after {
  background-color: #ddd;
}

.branded-title.st1:after {
  background-color: #f8b332;
}

.branded-title.st2:after {
  background-color: #0071ba;
}

/* ==> Lists */
.list-spaced li {
  margin-bottom: 0.5em;
  margin-top: 0.5em;
}

.list-inline {
  list-style: none outside none;
  margin-left: -5px;
}

.list-inline li {
  margin: 0 5px;
  display: inline-block;
}

.list-pretty {
  border-bottom: 1px dashed #e0e0e0;
  margin-left: 0;
}

.list-pretty li {
  display: block;
  position: relative;
  border-top: 1px dashed #e0e0e0;
  padding: 5px 0 5px 18px;
}

.list-pretty li:before {
  font-family: 'FontAwesome';
  content: '\f054';
  color: #d5cd9e;
  display: block;
  position: absolute;
  left: 0;
  top: 5px;
}

.list-pretty a {
  color: #555;
}

.list-pretty a:hover {
  color: #0071ba;
}

/* ==> Forms */
.fields {
  margin-left: 0;
  list-style: none outside none;
}

.fields li {
  margin-bottom: 0.84211rem;
}

.fields li:last-child {
  margin-bottom: 0;
}

.Inputfield {
  margin-bottom: 1em;
}

.Inputfield.InputfieldHidden {
  display: none;
}

.contact-block .FormBuilder .error,
.contact-block .InputfieldForm .error {
  color: #f8b332;
}

.contact-block .InputfieldStateRequired > .InputfieldHeader:first-child::after {
  color: #f8b332;
}

label.required {
  position: relative;
}

label.required:after {
  content: '*';
  color: #e75424;
  margin-left: 2px;
}

label.error {
  color: #e75424;
}

.form-search-events .form-element {
  margin-bottom: 20px;
}

.form-search-events .checkbox-holder label,
.form-search-events .checkbox-holder input[type="checkbox"] {
  float: left;
}

.form-search-events .checkbox-holder label {
  margin-right: 5px;
}

.form-search-events .checkbox-holder input[type="checkbox"] {
  margin: 6px 0;
}

.form-search-events .postfix {
  color: rgba(255, 255, 255, 0.7);
  font-size: 1.2em;
  line-height: 2.4375rem;
}

.form-search-events select option[value=""] {
  color: #999999;
}

/* ==> Tables */
table.flat tbody {
  border-width: 0 0 1px 0;
}

table.flat tbody tr:nth-child(2n) {
  background-color: transparent;
}

table.flat th {
  text-align: left;
}

table.flat td {
  border-top: 1px solid #ddd9d6;
}

@media screen and (min-width: 41.25em) {
  .fact-table tr td:nth-last-child(-n+2) {
    width: 170px;
  }
}

.fact-table tr td:last-child {
  border-left: 1px solid #ddd9d6;
}

/* ==> Pagination */
.MarkupPagerNavWrap {
  text-align: center;
}

.MarkupPagerNav {
  clear: both;
  margin: 1em auto;
  display: inline-block;
  overflow: hidden;
}

.MarkupPagerNav li {
  display: inline;
  list-style: none;
  margin: 0;
}

.MarkupPagerNav li a,
.MarkupPagerNav li.MarkupPagerNavSeparator {
  display: block;
  float: left;
  padding: 2px 9px;
  color: #fff;
  background: #fbb524;
  margin-right: 3px;
  font-size: 12px;
  line-height: 20px;
  font-weight: bold;
  text-transform: uppercase;
}

.MarkupPagerNav li.MarkupPagerNavOn a,
.MarkupPagerNav li a:hover {
  color: #fff;
  background: #0071ba;
  text-decoration: none;
}

.MarkupPagerNav li.MarkupPagerNavSeparator {
  display: inline;
  color: #777;
  background: #d2e4ea;
  padding-left: 3px;
  padding-right: 3px;
}

.MarkupPagerNav .MarkupPagerNavPrevious a,
.MarkupPagerNav .MarkupPagerNavNext a {
  background: transparent;
  color: #0071ba;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
}

.MarkupPagerNav .MarkupPagerNavPrevious a i,
.MarkupPagerNav .MarkupPagerNavNext a i {
  line-height: 20px;
}

/* ==> Icons */
.fa-file-pdf-o {
  color: #e41e00;
}

/* ==> Images */
img.align_left {
  float: left;
}

img.align_right {
  float: right;
}

img.align_center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

img.float-left,
img.float-right,
img.align_left,
img.align_right {
  margin-bottom: 0.5em;
}

img.float-left,
img.align_left {
  margin-right: 1.5em;
}

img.float-right,
img.align_right {
  margin-left: 1.5em;
}

img.responsive {
  max-width: 50%;
}

@media screen and (min-width: 64em) {
  img.responsive {
    max-width: none;
  }
}

/* ==> Videos */
.video-wrap {
  overflow: hidden;
  padding-top: 56.25%;
  /* 16:9 */
  position: relative;
}

.video-wrap iframe {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  border: 0 none;
}

.video-caption {
  text-align: center;
}

.video-thumbnail {
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  display: block;
  margin-bottom: 5px;
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  transition: all 250ms ease;
}

.video-thumbnail:before {
  background: rgba(51, 51, 51, 0.2);
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

.video-thumbnail:after {
  background: url("images/icon-play.png") no-repeat center center;
  background-size: 50px 50px;
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  transition: all 250ms ease;
}

.video-thumbnail.video-noplay:after {
  content: none;
}

.video-thumbnail:hover:after {
  background-size: auto;
}

/* ==> Maps */
.google-map {
  height: 300px;
  width: 100%;
}

@media screen and (min-width: 41.25em) {
  .google-map {
    height: 430px;
  }
}

/* ==> Other */
.columns.collapse {
  padding-left: 0;
  padding-right: 0;
}

.reveal-overlay {
  z-index: 9999;
}

.reveal-inner {
  padding: 30px;
}

.field-wrap {
  position: relative;
}

/* ==> FormBuilder stufff */
.InputfieldStateRequired > .InputfieldHeader:first-child:after {
  /* Required state: Add asterisk to indicate field is required */
  content: '*';
  margin-left: 0.25em;
  color: red;
}

/* error messages */
.FormBuilder .error,
.InputfieldForm .error {
  color: red;
}

/* ==> Form Styles: Schedule a Visit */
.page-section--schedule-a-visit {
  background: url("images/bg-vectors1.png") no-repeat right bottom #efefef;
}

.page-section--schedule-a-visit input[type="text"],
.page-section--schedule-a-visit input[type="number"],
.page-section--schedule-a-visit select,
.page-section--schedule-a-visit textarea {
  background-color: #fff;
}

.schedule-visit-form-feedback,
.form-processor {
  display: none;
}

.schedule-visit-form-feedback {
  padding-bottom: 50px;
}

.field-wrap {
  margin-bottom: 15px;
}

.field-wrap--required:after {
  position: absolute;
  content: '*';
  color: #e75424;
  left: -3px;
  top: -7px;
}

.reveal-modal-team-member .polaroid-box {
  -moz-transform: rotate(5deg);
  -o-transform: rotate(5deg);
  -webkit-transform: rotate(5deg);
}

.reveal-modal-team-member h2, .reveal-modal-team-member h3, .reveal-modal-team-member h4 {
  color: #0071ba;
}

.reveal-modal-team-member p:last-child {
  margin-bottom: 0;
}

.reveal-modal-team-member-body {
  font-size: 13px;
}

.reveal-modal-team-member-body h5 {
  color: #0071ba;
}

@media screen and (min-width: 41.25em) {
  .reveal-modal-team-member {
    padding: 30px;
  }
  .reveal-modal-team-member .polaroid-box {
    float: left;
    margin-right: 30px;
    margin-bottom: 30px;
  }
}

.container-note--pop {
  background: #e86a99;
  background: -webkit-linear-gradient(top left, #e977a7 0%, #e86897 25%, #eb8cb5 81%, #eb8cb5 72%, #eb8cb5 72%, #f3b7d1 100%);
  background: -o-linear-gradient(top left, #e977a7 0%, #e86897 25%, #eb8cb5 81%, #eb8cb5 72%, #eb8cb5 72%, #f3b7d1 100%);
  background: linear-gradient(to bottom right, #e977a7 0%, #e86897 25%, #eb8cb5 81%, #eb8cb5 72%, #eb8cb5 72%, #f3b7d1 100%);
  /* W3C */
  color: #fff;
  display: block;
  margin-bottom: 50px;
  padding: 30px;
  position: relative;
}

.container-note--pop:before, .container-note--pop:after {
  position: absolute;
  content: '';
}

.container-note--pop:before {
  background: url("images/shadow-note-right1.png") no-repeat left top;
  top: 10px;
  right: -19px;
  height: 186px;
  width: 19px;
}

.container-note--pop:after {
  background: url("images/shadow-note-bottom1.png") no-repeat left top;
  background-size: 100% 100%;
  top: 100%;
  left: 0;
  width: 100%;
  padding-bottom: 11.22%;
}

.container-note--pop .blockquote-basic {
  padding-left: 0;
  padding-right: 0;
}

.blockquote-basic {
  border: 0 none;
  padding: 15px;
}

.blockquote-basic,
.blockquote-basic p,
.blockquote-basic cite {
  color: inherit;
}

.blockquote-basic .blockquote-body {
  font-style: italic;
}

.blockquote-basic .blockquote-cite {
  font-size: 1em;
  font-style: normal;
}

@media screen and (min-width: 41.25em) {
  .blockquote-medium-large .blockquote-body {
    font-size: 1.2632em;
  }
}

.quote-box {
  background: url("images/quote-vector-primary-square.png") no-repeat left top;
  color: #fff;
  background-size: 100% 100%;
  max-width: 350px;
  padding: 45px 35px 55px 45px;
  text-align: center;
}

.quote-box blocquote {
  color: inherit;
}

.quote-box blocquote p:last-child {
  margin-bottom: 0;
}

.quote-box--pop {
  background-image: url("images/quote-vector-pop-square.png");
}

.quote-box--center {
  margin-left: auto;
  margin-right: auto;
}

.job-list {
  list-style: none outside none;
  margin-left: 0;
}

.job-list li {
  background: url("images/border-pop.png") no-repeat left top;
  background-size: 100% 5px;
  list-style-image: none !important;
  padding: 35px 0;
}

.job-list li:nth-child(even) {
  background-image: url("images/border-secondary.png");
}

.job-list p:last-child {
  margin-bottom: 0;
}

.smallprint {
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.field-wrap--icon-after .field-element {
  padding-right: 50px;
}

.field-wrap--icon-after .field-icon {
  width: 50px;
  padding-left: 10px;
  float: right;
}

/* ----
	Page Helpers
	========================================================================== */
/* ==> Text Styles */
.textcolor-primary {
  color: #0071ba !important;
}

.textcolor-success {
  color: #6ca642 !important;
}

.textcolor-gray-dark {
  color: #555 !important;
}

.text-light {
  font-weight: 300 !important;
}

.text-strong {
  font-weight: bold !important;
}

.text-center {
  text-align: center !important;
}

.text-underline {
  text-decoration: underline;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-italic {
  font-style: italic;
}

.text-serif {
  font-family: "Roboto Slab", Georgia, serif !important;
}

.text-sansserif {
  font-family: "Roboto", "Source Sans Pro", Arial, sans-serif !important;
}

.text-condensed {
  font-family: "din-condensed-web", "Source Sans Pro", Arial, sans-serif !important;
}

/* ==> Backgrounds */
.bgcolor-primary {
  background-color: #0071ba !important;
}

.bgcolor-secondary {
  background-color: #fbb524 !important;
}

.bgcolor-lightgrey {
  background-color: #eee !important;
}

.bgcolor-brand2 {
  background-color: #d5cd9e !important;
}

.bgcolor-brand3 {
  background-color: #cbbda6 !important;
}

/* ==> Margins */
.mb-0 {
  margin-bottom: 0px !important;
}

.mb-3 {
  margin-bottom: 3px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-7 {
  margin-bottom: 7px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

/* ==> Other */
@media screen and (min-width: 41.25em) {
  .font-size-large {
    font-size: 2.5em;
  }
}

.h-165 {
  height: 165px !important;
}

.h-220 {
  height: 220px !important;
}

hr.spacer {
  border-color: transparent;
}

hr.spacer--small {
  border-color: transparent;
  margin-top: 7px;
  margin-bottom: 7px;
}

.lead {
  font-size: 1.23em;
  line-height: inherit;
}
